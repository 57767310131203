<template>
  <div class="overlay-main"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "./Overlay.scss"
</style>
