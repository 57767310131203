<template>
  <div class="overlay-loader">
    <Loading></Loading>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {
    Loading: () => import(/* webpackChunkName: "loading" */ "@/components/Templates/Loading.vue"),
  },
};
</script>

<style scoped>
@import "./overlayLoader.scss"
</style>
